

.titan-screen {
  width: 100vw;
  height: 100vh;
  background: url('./assets/wallpaper-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: url('./assets/cursor.png'), auto;
}


.titan-screen .dock {
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0px);
  box-sizing: border-box;
  background: rgba(74, 74, 74, 0.39);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 5px;
  align-content: center;
  gap: 5px;
  height: 69px;
}

.titan-screen .dock .icon {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 6px;
  padding: 5px;
}

.titan-screen .dock .icon .squirtle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 40px; */
  aspect-ratio: 1;
  background-color: #FFFFFF;
  border-radius: 9.25px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
  transition: 150ms;
}

.titan-screen .dock .icon:hover > .squirtle {
  cursor: url('./assets/pointer.png'), auto;
  /* width: 80px; */
  transition: 150ms;
}

.icon.transition {
  transition: transform 0.2s;
}

.titan-screen .dock .icon .feature-open {
  width: 4px;
  aspect-ratio: 1;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
}

.titan-screen .titan-navigator {
  width: 100%;
  padding: 0px 3px;
  position: fixed;
  height: 20px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.18);
  background-blend-mode: darken;
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}

.titan-screen .titan-navigator .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* gap: 2px; */
}

.titan-screen .titan-navigator .row .prop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3.25px 9px;
  gap: 8px;
  align-self: stretch;
  /* background: rgba(255, 255, 255, 0.2); */
  border-radius: 3.25px;
}

.titan-screen .titan-navigator .row .prop:hover {
  cursor: url('./assets/pointer.png'), auto;
}

.titan-screen .titan-navigator .row .prop h3 {
  font-family: 'Sf Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 30px 80px rgba(0, 0, 0, 0.7), 0px 0.8px 3.25px rgba(0, 0, 0, 0.2);
  margin-block: 0px;
  user-select: none;
}

.titan-screen .titan-navigator .row .prop h3 span {
  font-weight: 600;
}