
html {
  background: black;
}

body {
  margin: 0px;
  max-width: 100%;
  overflow-x: hidden;
}


.heatcontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: black;
  min-height: calc(100vh - 100px);
  overflow-x: hidden;
  color: white;
  font-family: Altima;
  padding: 50px 25px;
}

.heatcontainer .header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 7px;
}

.heatcontainer .header .trid-logo {
  width: 16px;
  aspect-ratio: 1;
  background-image: url('./assets/trident.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.heatcontainer .container {
  flex: 1;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 25px;
}

.heatcontainer .container .flags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.heatcontainer .container .flags .flag {
  width: 20px;
  aspect-ratio: 1;
}

.heatcontainer .container .flags .flag-us {
  background-image: url('./assets/us.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.heatcontainer .container .flags .flag-ue {
  background-image: url('./assets/ue.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.heatcontainer .container .ig-logo {
  width: 90px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: linear-gradient(238.74deg, #C913B9 -2.57%, #F9373F 44.58%, #FECD00 91.55%);
  border-radius: 100px;
  transition: 300ms;
}

.heatcontainer .container .ig-logo:hover {
  cursor: pointer;
}

.heatcontainer .container .ig-logo:active {
  background: rgba(255, 255, 255, 0.55);
  opacity: 0.5;
  transition: 100ms;
}

.heatcontainer .container .ig-logo .image {
  width: calc(76px + 5px*2);
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: url('./assets/avatar.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.heatcontainer .container .links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 25px;
}

.heatcontainer .container .links svg {
  transition: 300ms;
}

.heatcontainer .container .links svg:hover {
  cursor: pointer;
  opacity: 0.5;
  transform: scale(0.9) translate(+1px, +1px) rotate(4deg);
  transition: 300ms;
}

.heatcontainer .container .links .big-logo {
  width: 30px;
  aspect-ratio: 1;
  background-image: url('./assets/trident.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 300ms;
}

.heatcontainer .container .links .big-logo:hover {
  cursor: pointer;
  scale: 1.1;
  transition: 300ms;
}

.heatcontainer .link {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  padding: 5px 0px;
  gap: 5px;
  transition: 300ms;
}

.heatcontainer .link:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: 300ms;
}

.heatcontainer .link h4 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #E0F1FF;
  margin-block: 0px;
  user-select: none;
}

.heatcontainer h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 200%;
  text-align: center;
  letter-spacing: -0.015em;
  color: rgba(255, 255, 255, 0.5);
  margin-block: 0px;
  user-select: none;
}

.heatcontainer h5 a {
  color: #E0F1FF;
  text-decoration: none;
}

.heatcontainer .loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.heatcontainer .loading .trid-logo {
  width: 25px;
  aspect-ratio: 1;
  background-image: url('./assets/trident.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.heatcontainer .loading .loader {
  width: 50px;
  aspect-ratio: 1;
  background-image: url('./assets/loader.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: linear infinite smartrotation 1s;
}

@keyframes smartrotation {
  0% {
    transform: rotate(0deg);
  }
  /* 33% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-720deg);
  } */
  100% {
    transform: rotate(-360deg);
  }
}

.ryse-helper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  color: 1;
  min-height: 100vh;
  background-color: black;
}

.ryse-container-helper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  color: white;
  min-height: 100vh;
  background-color: black;
}

hr {
  color: red;
}


.high15::selection {
  background: rgba(255, 255, 255, 0.15);
}

.high25::selection {
  background: rgba(255, 255, 255, 0.25);
}

.cg-input {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  gap: 4px;
}

.cg-input h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(255, 255, 255, 0.25);
  align-self: stretch;
  margin-block: 0px;
}

.cg-input .rightf {
  text-align: right;
}

.cg-input .rightf:hover {
  cursor: pointer;
}

.cg-input h5::selection {
  background: rgba(255, 255, 255, 0.15);
}

.cg-input h5 span {
  font-family: Altima;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
  margin-block: 0px;
}

.cg-input h5 span::selection {
  background: rgba(255, 255, 255, 0.15);
}

.cg-input .base {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 0px;
  gap: 5px;
  /* height: 32px; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  align-self: stretch;
}

.cg-input .base input {
  flex: 1;
  background: none;
  border: none;
  outline: none;
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: white;
}

.cg-input .base input::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.cg-input .base input::selection {
  background: rgba(255, 255, 255, 0.25);
}

.cg-input .base textarea {
  flex: 1;
  background: none;
  border: none;
  outline: none;
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: white;
  max-height: 250px;
}

.cg-input .base textarea::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.cg-input .base textarea::selection {
  background: rgba(255, 255, 255, 0.25);
}

.cg-input .base h6 {
  font-family: Altima;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
  margin-block: 0px;
}

.cg-input .base h6:hover {
  cursor: pointer;
}


.ryse-container-helper .helper-screening {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 150px 25px 150px 25px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.125) 100%), url('./assets/back.jpg'), #000000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ryse-container-helper .helper-screening-background-reviews {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.125) 100%), url('./assets/back2.jpg'), #000000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ryse-container-helper .helper-screening .navigator {
  position: fixed;
  top: 0px;
  width: 100%;
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 30px 25px; */
  height: 83px;
}

.ryse-container-helper .helper-screening .navigator .navigator-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  padding: 0px 35px;
  align-items: center;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill-align-left {
  justify-content: flex-start;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill-align-center {
  justify-content: center;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill-align-right {
  justify-content: flex-end;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill p {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.015em;
  color: rgba(255, 255, 255, 0.5);
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill .onliner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 5px;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill .onliner h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.05em;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .navigator .navigator-container .navigation-fill .onliner .box-status {
  width: 5px;
  aspect-ratio: 1;
  border-radius: 100px;
}

.ryse-container-helper .helper-screening .container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  max-width: 650px;
}

.ryse-container-helper .helper-screening .container .titan-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 16px 16px 16px;
  gap: 16px;
  isolation: isolate;
  background: rgba(246, 246, 246, 0.6);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 38px 90px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  align-self: stretch;
}

.ryse-container-helper .helper-screening .container .titan-alert .titan-icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.ryse-container-helper .helper-screening .container .titan-alert .text-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.ryse-container-helper .helper-screening .container .titan-alert .text-container h3 {
  font-family: Altima;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  align-self: stretch;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .container .titan-alert .text-container p {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  align-self: stretch;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .container .titan-alert .text-container p span {
  color: #1B88FF;
}

.ryse-container-helper .helper-screening .container .titan-alert .buttons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.ryse-container-helper .ios-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 7px;
  gap: 8px;
  min-width: 96px;
  height: 28px;
  border-radius: 5px;
}

.ryse-container-helper .ios-button:hover {
  cursor: pointer;
}

.ryse-container-helper .ios-button-shadow {
  background: rgba(0, 0, 0, 0.015);
  color: black;
}

.ryse-container-helper .ios-button-active {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0) 100%), #007AFF;
  color: white;
}

.ryse-container-helper .ios-button h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  margin-block: 0px;
  user-select: none;
}

.ryse-container-helper .helper-screening .container .helper-interface {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 0px;
  gap: 25px;
  align-self: stretch;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  align-self: stretch;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title h1 {
  font-family: Altima;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  letter-spacing: -0.05em;
  color: #FFFFFF;
  margin-block: 0px;
  display: inline-block;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title h1 span {
  display: inline-block;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title h1 span .big-heart {
  width: 29px;
  aspect-ratio: 1;
  background: url('./assets/heart.png');
  background-size: cover;
  background-position: center;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title h2 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.015em;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title h3 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.015em;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title h3 span {
  font-weight: 500;
}

.ryse-container-helper .helper-screening .container .helper-interface .big-title p {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.005em;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional p {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0em;
  align-self: stretch;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional h6 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
  margin-block: 0px;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional .make-row {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 10px;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional .bp-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 15px 0px; */
  height: 46px;
  background: rgba(255, 255, 255, 0.05);
  flex: 1;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional .bp-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional .bp-button:active {
  opacity: 0.5;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional .box-refresh {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  aspect-ratio: 1;
  background: rgba(255, 255, 255, 0.05);
}

.ryse-container-helper .helper-screening .container .helper-interface .actional .box-refresh:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ryse-container-helper .helper-screening .container .helper-interface .actional .box-refresh:active {
  opacity: 0.5;
}


.ryse-container-helper .helper-screening .container .helper-interface .actional .bp-button .little-heart {
  width: 16px;
  aspect-ratio: 1;
  background: url('./assets/heart.png');
  background-size: cover;
  background-position: center;
}


.ryse-container-helper .footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 50px 25px; */
  padding: 50px 25px;
  /* margin-top: 200px; */
  gap: 35px;
  background-image: url('./assets/foot2.jpg');
  background-size: covert;
  background-position: center -1px;
  background-repeat: repeat;
  align-self: stretch;
}

.ryse-container-helper .footer .central:hover {
  cursor: pointer;
}

.rysecontainerapp {
  align-self: stretch;
  padding: 15px;
  /* padding-inline: 25px; */
  padding: 25px;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  gap: 25px;
  background: black;
  color: white;
  background: url('./assets/backg.jpg');
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.rysecontainerapp h1, h2, h3, h4, h5, h6, p {
  margin-block: 0px;
}

.rysecontainerapp .ryse-nav {
  align-self: stretch;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rysecontainerapp .ryse-nav .avt {
  width: 26px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  border-radius: 100px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1)
}

.rysecontainerapp .ryse-assets {
  align-self: stretch;
  background: url('./assets/landscape.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  border-radius: 12px;
}

.rysecontainerapp .ryse-assets h4 {
  font-family: Altima;
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  letter-spacing: 0.32em;
  color: #86BFF2;
  align-self: stretch;
  user-select: none;
}

.rysecontainerapp .ryse-assets .counter {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0px;
  gap: 5px;
  align-self: stretch;
}

.rysecontainerapp .ryse-assets .counter h1 {
  font-family: Altima;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 75%;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  user-select: none;
}

.rysecontainerapp .ryse-assets .counter .sub-counter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 1px;
}

.rysecontainerapp .ryse-assets .counter .sub-counter h3 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 75%;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
}

.rysecontainerapp .ryse-assets .make-rows {
  align-self: stretch;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 15px;
}

.rysecontainerapp .ryse-assets .make-rows h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  user-select: none;
}

.rysecontainerapp .ryse-assets .make-rows h5 span {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
}

.rysecontainerapp .actions {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.rysecontainerapp .actions h3 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
  user-select: none;
}

.rysecontainerapp .actions .gscreen {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0px;
  gap: 10px;
  align-self: stretch;
}

.verticaltx {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 9px;
  flex: 1;
  /* min-width: 70px; */
  min-width: 80px;
}

.rysecontainerapp .actions .payments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0px;
  gap: 10px;
  align-self: stretch;
}

.rysecontainerapp .actions .payments .payment-method {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px; */
  gap: 12px;
  min-width: 150px;
  height: 46px;
  border-radius: 8px;
  flex: 1;
}

.rysecontainerapp .actions .languages {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.rysecontainerapp .actions .languages .verticaltx {
  min-width: 0;
}

.verticaltx h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
  user-select: none;
}

.rysecontainerapp .rbutton {
  background: #0D0D0D;
  border: 1px solid rgba(134, 191, 242, 0.15);
  box-shadow: inset 0px 0px 48px rgba(199, 211, 234, 0.05), inset 0px 0px 1px rgba(199, 211, 234, 0.12);
  border-radius: 12px;
  transition: 150ms;
}

.rysecontainerapp .rbutton:hover {
  opacity: 0.75;
  transition: 150ms;
  cursor: pointer;
}

.rysecontainerapp .rbuttonactive {
  background: linear-gradient(0deg, rgba(134, 191, 242, 0.15), rgba(134, 191, 242, 0.15)), #0D0D0D;
  border: 1px solid rgba(134, 191, 242, 0.15);
  box-shadow: 0px 0px 10px rgba(134, 191, 242, 0.25), inset 0px 0px 48px rgba(199, 211, 234, 0.05), inset 0px 0px 1px rgba(199, 211, 234, 0.12);
}

.rysecontainerapp .loader {
  width: 28px;
  aspect-ratio: 1;
  background: url('./assets/loader.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scale: 0.8;
  animation-play-state: paused;
}

.rysecontainerapp .loader-on {
  animation: loading 1s infinite linear;
  animation-play-state: running;
}

@keyframes loading {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: -360deg;
  }
}

.rysecontainerapp .feet {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  padding: 0px 15px 15px 15px;
}

.rysecontainerapp .feet .neck {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.rysecontainerapp .feet .neck h3 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  align-self: stretch;
  user-select: none;
}

.rysecontainerapp .feet .neck h4 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
  user-select: none;
}

.rysecontainerapp .feet .bottom {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.rysecontainerapp .feet .bottom .rs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.rysecontainerapp .feet .bottom .rs h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
}

.rysecontainerapp .feet .bottom .links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.rysecontainerapp .feet .bottom .links h6 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
}


.ryse-container-helper .helper-screening .container .helper-interface .radio {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.ryse-container-helper .helper-screening .container .helper-interface .radio .verticaltx {
  border-radius: 8px;
  min-width: 150px;
}

.ryse-container-helper .helper-screening .container .helper-interface .radio .verticaltx:hover {
  cursor: pointer;
}
/* h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
  user-select: none;
} */


@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap');

.castel-del-monte {
  width: 100vw;
  background: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 25px;
}

.castel-del-monte .sketchfab-embed-wrapper {
  flex: 1;
  /* max-width: 1500px; */
  height: 670px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  
  border: none;
  position: relative;
  z-index: 1;
}

.castel-del-monte .swift {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.castel-del-monte iframe {
  border: none;
  width: 100%;
  /* height: 100%; */
  height: 715px;
  flex-grow: 0;
  flex-shrink: 0;
  /* transform: translate(0px, -45px); */
}

.castel-del-monte iframe .model-name {
  display: none;
}

.castel-del-monte #ssvg {
  position: fixed;
  filter: drop-shadow(0px 0px 50px black);
  
  top: 50%;
  transform-origin: center;
  left: 50%;
  transition: 300ms;
  /* top: 10vh; */
  z-index: 10;
}

.castel-del-monte .svg-central {
  scale: 2;
  transform: translate(-25%, -25%);
}

.castel-del-monte .svg-bottom {
  scale: 1;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1000px) {
  .castel-del-monte .svg-central {
    scale: 1;
    transform: translate(-50%, -50%);
  }
}

.castel-del-monte p {
  padding: 25px;
  align-self: stretch;
  letter-spacing: 0.02em;
  text-align: center;
  font-family: Altima;
  font-size: 11px;
  font-size: 400;
  color: rgba(255, 255, 255, 0.5);
}

.castel-del-monte a {
  text-decoration: none;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.75);
}

.castel-del-monte iframe .ready {
  display: none;
}

.castel-del-monte .overlay-bottom {
  position: absolute;
  width: 41px;
  aspect-ratio: 1;
  background: black;
  filter: drop-shadow(0px 0px 25px black);
  left: 8px;
  border-radius: 100px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.castel-del-monte .overlay-bottom h4 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  /* letter-spacing: 0.15em; */
  text-transform: uppercase;
  color: white;
  user-select: none;
}

.castel-del-monte .overlay-bottom-v2 {
  position: absolute;
  /* width: 100%; */
  /* max-width: 250px; */
  background: black;
  padding: 15px 15px 15px 15px;
  left: 5px;
  border-radius: 8px;
  
  bottom: 5px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.castel-del-monte .overlay-bottom-v2 h6 {
  font-family: Altima;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
}