

.iris-container {
  position: relative;
  align-self: stretch;
  background: black;
  overflow: hidden;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 50px;
  box-sizing: border-box;
}

.iris-container > * {
  z-index: 5;
}

.iris-container .video-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.iris-container video {
  z-index: 3;
  transition-timing-function: cubic-bezier(1.000, 0.250, 0.020, 0.930);
  /* transform: translate(-50%, -50%); */
}

@media only screen and (max-width: 1000px) {
  .iris-container video {
    height: 80%;
  }
}

.iris-container .video-talking {
  animation: ease 2s infinite talk;
  transform-origin: center center;
  transition: 300ms;
  /* transform: translate(-50%, -50%); */
}

@keyframes talk {
  0% {
    scale: 1;
  }
  10% {
    scale: 1.2;
  }
  20% {
    scale: 1;
  }
  30% {
    scale: 1.2;
  }
  40% {
    scale: 1.3;
  }
  50% {
    scale: 1;
  }
  60% {
    scale: 1.2;
  }
  70% {
    scale: 1.4;
  }
  80% {
    scale: 1.2;
  }
  90% {
    scale: 1;
  }
  100% {
    scale: 1;
  }
}

.iris-container .swift {
  pointer-events: none;
}

.iris-container .iris-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 600px 0px 0px;
  gap: 10px;
  box-sizing: border-box;
  max-height: 640px;
  max-width: 800px;
  flex: 1;
  pointer-events: none;
}

.iris-container .iris-content .container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 4px;
  flex: 1;
  height: 80px;
  max-height: 80px;
  overflow: hidden;
  pointer-events: all;
}

.iris-container .iris-content .container .v-scroll-container {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.iris-container .iris-content .container h2 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: -0.02em;
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  align-self: stretch;
  animation: fd 250ms linear;
  user-select: none;
  text-rendering: optimizeLegibility;
  filter: blur(.2px);
}

@keyframes fd {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}