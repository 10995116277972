
.titan-screen {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.titan-screen .titan-window {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 1100px;
  height: 500px;
  background: #1B1C1D;
  box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.4), 0px 0px 2.5px rgba(0, 0, 0, 0.25), inset 0px 0px 2.5px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 18px;
}

.titan-screen .titan-window .titan-window-drag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  gap: 15px;
  align-self: stretch;
}

.titan-screen .titan-window .titan-window-drag-enabled:hover {
  /* cursor: grabbing; */
  cursor: url('../cursors/handgrabbing.png'), auto;
}

.titan-screen .titan-window .titan-window-drag .titan-window-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 6px;
}

.titan-screen .titan-window .titan-window-drag .titan-window-controls .titan-window-controls-dot {
  box-sizing: border-box;
  width: 9px;
  aspect-ratio: 1;
  background: rgba(255, 255, 255, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  transition: 150ms;
}

.titan-screen .titan-window .titan-window-drag .titan-window-controls .titan-window-controls-dot:hover {
  cursor: pointer;
  opacity: 0.75;
  transition: 150ms;
}

.titan-screen .titan-window .titan-window-drag .titan-window-controls .titan-window-controls-dot-close {
  background: #FF5F57;
}

.titan-screen .titan-window .titan-window-drag .titan-window-controls .titan-window-controls-dot-minimize {
  background: #FEBC2E;
}

.titan-screen .titan-window .titan-window-drag .titan-window-controls .titan-window-controls-dot-zoom {
  background: #28C840;
}

.titan-screen .titan-window .titan-window-content {
  align-self: stretch;
  flex: 1;
  margin: 0px 2px 2px 2px;
  border-radius: 0px 0px 16px 16px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}